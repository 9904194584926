class EventDate {
  constructor(date) {
    const dateObj = new Date(`${date}T12:00:00`)
    this.date = dateObj.getDate()
    const monthLongNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
    const monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
    const daysOfTheWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    this.monthShort = monthShortNames[dateObj.getMonth()]
    this.monthLong = monthLongNames[dateObj.getMonth()]
    this.dayOfWeek = daysOfTheWeek[dateObj.getDay()]
    this.year = dateObj.getFullYear()
  }
}

export default EventDate